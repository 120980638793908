import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 0 0 80px; */
  ${MEDIA.TABLET`
    margin: 80px 0 0;
  `};
  /* justify-content: space-between; */
  .ql-align-center {
    text-align: center;
  }
  ul {
    list-style: initial;
    list-style-position: outside;
    padding: 0 0 0 16px;
    li {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 4px 0;
    }
  }
  h1 {
    font-size: 2.8rem;
    line-height: 3.4rem;
    padding: 24px 0 16px;
  }
  h2 {
    font-size: 2.2rem;
    line-height: 2.8rem;
    padding: 16px 0 8px;
  }
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    max-width: 75ch;
    margin: 0;
  }
  div {
    box-sizing: border-box;
  }
  img {
    display: block;
    width: 100%;
    max-width: 100%;
    /* position: absolute; */
  }
  .half {
    width: 50%;
    ${MEDIA.PHONE`
        width: 100%;
        `};
  }
  .col {
    &-12 {
      width: 100%;
    }
    &-1 {
      width: calc(1 / 12 * 100%);
    }
    &-2 {
      width: calc(2 / 12 * 100%);
    }
    &-3 {
      width: calc(3 / 12 * 100%);
    }
    &-4 {
      width: calc(4 / 12 * 100%);
    }
    &-5 {
      width: calc(5 / 12 * 100%);
    }
    &-6 {
      width: calc(50%);
    }
    &-7 {
      width: calc(7 / 12 * 100%);
    }
    &-8 {
      width: calc(8 / 12 * 100%);
    }
    &-9 {
      width: calc(9 / 12 * 100%);
    }
    &-10 {
      width: calc(10 / 12 * 100%);
    }
    &-11 {
      width: calc(11 / 12 * 100%);
    }
  }
  .center {
    margin: 0 auto;
  }
  ${MEDIA.TABLET`
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
        width: 100%;
    }
        `};
  a {
    /* padding: 2px 0; */
    color: ${props => props.theme.linkColor} !important;
    text-decoration: none;
    position: relative;
    &:before {
      transition: 0.2s all ease-in;
      position: absolute;
      content: '';
      height: 1px;
      width: 20px;
      bottom: 0;
      left: 0;
      background: ${props => props.theme.primaryColor};
    }
    &:hover {
      &:before {
        width: 100%;
      }
    }
  }
  .image-gallery-fullscreen-button.active {
    top: 0;
    bottom: auto;
    background-color: #000;
    &:before {
      content: '\\274c';
    }
  }
  .image-gallery-content {
    .image-gallery-image {
      img {
        max-height: 500px;
        height: 500px;
        ${MEDIA.TABLET`
      height: auto;
        `};
      }
    }
  }
  .image-gallery-content.fullscreen {
    .image-gallery-image {
      img {
        /* max-height: 75vh; */
        width: auto;
        margin: 0 auto;
      }
    }
  }

  .image-gallery {
    max-width: 100%;
    overflow: hidden;
  }
`;
