import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import Layout from 'components/layout';
import Head from 'components/head';
import Box from 'components/box';
import PageBlocks from 'components/pageBlocks';

const Index = ({ data }) => {
  const fields = JSON.parse(data.buroBorkPage.field);

  return (
    <Layout locale={fields.locale}>
      <Head pageTitle={fields.title} locale={fields.locale} />
      {fields.page_rows.map((row, index) => {
        let classes = 'page_row';
        if (row.options && row.options.classes) {
          classes = classNames(classes, Object.values(row.options.classes));
        }
        return (
          <Box key={index} className={classes}>
            <PageBlocks blocks={row.page_blocks} />
          </Box>
        );
      })}
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query BuroBorkIndex {
    buroBorkPage(slug: { eq: "/" }) {
      id
      field
    }
  }
`;

export default Index;
